

import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { FaArrowRight } from "react-icons/fa";
import example from "../../assets/image/jpeg/examples.jpg";
 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { device, breakpoints } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';

const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;

const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

export default function Gummyfaq() {
    return (
        <>
          <Separator />
        <Section py={4} id="faq" bg="#f7f7fb" className="pb-md-5 mb pt-md-5">
          
      <Container className="pb-md-5 mb pt-md-5 pt-3 pb-3">
        <Row className="justify-content-center pb-4">
          <Col lg="12">
   
            <Iwrap>


<Itext as="h2">Questions about <span>Gummy Smile</span></Itext>
</Iwrap>
      <SecondText>
      Frequently Asked Questions

      </SecondText>
         
          </Col>
        </Row>


        <Row className="">
       
       


        <Col
            lg="12"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >

<Accordion allowZeroExpanded classname="">
  
    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton>
                What is a gummy smile?
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <p>
                A gummy smile, also known as excessive gingival display, is a condition in which a person's smile shows an excessive amount of their gum tissue above the upper front teeth when they smile. This can be caused by various factors such as genetics, excessive gum tissue growth, hyperactive upper lip muscles, or the positioning of the teeth and jaw.
            </p> 
        </AccordionItemPanel>
    </AccordionItem>
    <br />

    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton>
                How does the non-surgical gummy smile treatment work?
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <p>
                The procedure involves injecting small amounts of Botox into specific muscles around the upper lip, which temporarily inhibits muscle movement and helps create a more balanced smile.
            </p> 
        </AccordionItemPanel>
    </AccordionItem>
    <br />

    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton>
                What is the duration of the results after the treatment?
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <p>
                The duration of results from Botox injections for a gummy smile can vary from person to person, but typically the effects last for about 3 to 4 months.
            </p> 
        </AccordionItemPanel>
    </AccordionItem>
    <br />

    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton>
                How long does the treatment take?
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <p>
                The procedure typically takes only 10 minutes, making it a convenient option for those with busy schedules.
            </p> 
        </AccordionItemPanel>
    </AccordionItem>
    <br />

    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton>
                Is there any downtime after the treatment?
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <p>
                No, there is no downtime required after the treatment. Patients can return to their regular activities, including work, immediately after the procedure.
            </p> 
        </AccordionItemPanel>
    </AccordionItem>
    <br />

    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton>
                What is the cost of the non-surgical gummy smile treatment?
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <p>
                The cost of our non-surgical gummy smile treatment is £220, making it an affordable option for those seeking to improve their smile.
            </p> 
        </AccordionItemPanel>
    </AccordionItem>
    <br />

    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton>
                Does the treatment involve any pain?
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <p>
                Botox injections for a gummy smile are typically well tolerated, with most patients experiencing only minimal discomfort or a slight pinch during the procedure.
            </p> 
        </AccordionItemPanel>
    </AccordionItem>
    <br />

    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton>
                Are there any risks associated with the non-surgical treatment?
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <p>
                Our non-surgical gummy smile treatment is safe and minimally invasive, with few risks. However, as with any medical procedure, there may be minor risks such as temporary swelling or bruising.
            </p> 
        </AccordionItemPanel>
    </AccordionItem>
    <br />

    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton>
                Can anyone undergo this treatment?
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <p>
                While most individuals with a gummy smile are suitable candidates for the treatment, it's essential to undergo a consultation to determine eligibility and discuss any specific concerns.
            </p> 
        </AccordionItemPanel>
    </AccordionItem>
    <br />

    <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton>
                Will the results look natural?
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
            <p>
                Yes, our non-surgical gummy smile treatment aims to achieve natural-looking results, ensuring a harmonious balance between the lips, teeth, and gum display during smiling.
            </p> 
        </AccordionItemPanel>
    </AccordionItem>
</Accordion>



          </Col>
     




        </Row>



<br /> 
              <a href 
                                            ="https://connect.pabau.com/bookings.php?compid=8607" 
                                            activeClassName="active"
                                           
                                          
                                        >      <Bookbutton>
                
                
                Book Appointment
                
               
                </Bookbutton> </a>
        


       


        </Container>
       
        </Section>
 <SeparatorEnd />
 </>


    );
}













    

      